import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import { PlayArrow, Pause, SkipNext, SkipPrevious } from '@material-ui/icons';

import { useSpotify } from '../api';
import { CurrentlyPlaying } from '../api/types';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    background: theme.palette.common.black,
  },
  image: {
    width: 60,
    height: 60,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },
  progressTrack: {
    height: 4,
    background: theme.palette.grey[400],
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    background: theme.palette.primary.main,
  },
  progressContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gridGap: 10,
    padding: 10,
  },
  trackOverview: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr auto',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 10px',
    flex: 1,
    minWidth: 0,
  },
  textStyles: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  trackControls: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
}));

export const Player = () => {
  const {
    getCurrentTrack,
    skipToNext,
    skipToPrevious,
    pause,
    play,
  } = useSpotify();
  const [current, setCurrent] = useState<CurrentlyPlaying>();
  const classes = useStyles();

  const fetchCurrent = useCallback(() => {
    getCurrentTrack().then(setCurrent);
  }, [getCurrentTrack, setCurrent]);

  useEffect(() => {
    fetchCurrent();
    const interval = setInterval(() => {
      fetchCurrent();
    }, 10000);
    const progressInterval = setInterval(() => {
      setCurrent((curr) => {
        if (curr?.is_playing && curr.item) {
          const newProgress = curr.progress_ms + 1000;
          if (newProgress >= curr.item.duration_ms) {
            fetchCurrent();
            return curr;
          } else {
            return {
              ...curr,
              progress_ms: newProgress,
            };
          }
        } else {
          return curr;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(progressInterval);
    };
  }, [getCurrentTrack, setCurrent, fetchCurrent]);

  const percent = useMemo(
    () =>
      current?.item
        ? (current.progress_ms / current.item.duration_ms) * 100
        : 0,
    [current]
  );

  return current?.item ? (
    <div className={classes.container}>
      <div style={{ width: '100%' }}>
        <div className={classes.trackOverview}>
          <img
            className={classes.image}
            src={current.item.album.images?.[0]?.url}
            alt={current.item.name}
          />
          <div className={classes.textContainer}>
            <Typography className={classes.textStyles}>
              {current.item.name}
            </Typography>
            <Typography
              className={classes.textStyles}
              variant="caption"
              color="textSecondary"
            >
              {current.item.artists.map((artist) => artist.name).join(', ')}
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <IconButton onClick={() => skipToPrevious().then(fetchCurrent)}>
              <SkipPrevious />
            </IconButton>
            <IconButton
              onClick={() =>
                current.is_playing
                  ? pause().then(fetchCurrent)
                  : play().then(fetchCurrent)
              }
            >
              {current.is_playing ? (
                <Pause fontSize="large" />
              ) : (
                <PlayArrow fontSize="large" />
              )}
            </IconButton>
            <IconButton onClick={() => skipToNext().then(fetchCurrent)}>
              <SkipNext />
            </IconButton>
          </div>
        </div>
        <div className={classes.progressTrack}>
          <div
            className={classes.progressBar}
            style={{ width: `${percent}%` }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};
