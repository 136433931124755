import Spotify from 'spotify-web-api-js';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { stringify, parse } from 'query-string';
import axios from 'axios';
import { PATH_HOME } from '../utils/constants';
import { CurrentlyPlaying } from './types';

const ACCESS_TOKEN = 'spotify_access_token';
const REFRESH_TOKEN = 'spotify_refresh_token';
const SPOTIFY_AUTH_URL = 'https://accounts.spotify.com/authorize';

export interface SpotifyProviderProps {
  client: Spotify.SpotifyWebApiJs;
}

export interface SpotifyContext {
  isAuthenticated: boolean;
  user?: SpotifyApi.CurrentUsersProfileResponse;
  loading: boolean;
  skipToNext: () => Promise<any>;
  skipToPrevious: () => Promise<any>;
  play: () => Promise<any>;
  pause: () => Promise<any>;
  getCurrentTrack: () => Promise<CurrentlyPlaying>;
  loginWithRedirect: () => void;
  getToken: () => string | undefined;
  getUser: (id: string) => Promise<SpotifyApi.UserObjectPublic>;
  searchTracks: (term: string) => Promise<SpotifyApi.SearchResponse>;
  getTracks: (ids: string[]) => Promise<SpotifyApi.MultipleTracksResponse>;
}

export const SpotifyContext = React.createContext<SpotifyContext>(
  {} as SpotifyContext
);
export const useSpotify = (): SpotifyContext =>
  useContext<SpotifyContext>(SpotifyContext);
export const SpotifyProvider: React.FC<SpotifyProviderProps> = ({
  children,
  client,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<SpotifyApi.CurrentUsersProfileResponse>();
  const [spotifyClient, setSpotify] = useState<Spotify.SpotifyWebApiJs>(client);
  const [loading, setLoading] = useState(true);

  const getToken = useCallback(
    (params: { code: string } | { refresh_token: string }) => {
      setLoading(true);
      axios
        .get(`${process.env['REACT_APP_BACKEND_URL']}/token`, {
          params,
        })
        .then(({ data }) => {
          const { access_token, refresh_token } = data;
          if (access_token) {
            localStorage.setItem(ACCESS_TOKEN, access_token);
            spotifyClient.setAccessToken(access_token);
          }
          if (refresh_token) {
            localStorage.setItem(REFRESH_TOKEN, refresh_token);
          }
          spotifyClient
            .getMe()
            .then((me) => {
              setUser(me);
              setIsAuthenticated(true);
            })
            .catch((e) => {
              console.error(e);
              setIsAuthenticated(false);
            });
        })
        .catch(({ message }) => console.error(message))
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, spotifyClient, setIsAuthenticated]
  );

  const onRedirectCallback = useCallback((): void => {
    const { code } = parse(window.location.search);
    if (typeof code === 'string') {
      getToken({ code });
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [getToken]);

  useEffect(() => {
    const initSpotify = async (): Promise<void> => {
      setSpotify(client);

      if (window.location.search.includes('code=')) {
        onRedirectCallback();
      }

      const access_token = localStorage.getItem(ACCESS_TOKEN);
      const refresh_token = localStorage.getItem(REFRESH_TOKEN);
      if (access_token) {
        client.setAccessToken(access_token);

        try {
          const me = await client.getMe();
          setUser(me);
          setIsAuthenticated(true);
        } catch (e) {
          if (e.status === 401 && refresh_token) {
            getToken({ refresh_token });
          } else {
            console.error(e);
            setIsAuthenticated(false);
          }
        }
      } else {
        console.error('Auth config has missing or incorrect access_token');
      }

      setLoading(false);
    };
    initSpotify();
  }, [client, onRedirectCallback, getToken]);

  const loginWithRedirect = () => {
    const authUrl = new URL(SPOTIFY_AUTH_URL);
    authUrl.search = stringify({
      response_type: 'code',
      client_id: process.env['REACT_APP_SPOTIFY_CLIENT_ID'],
      scope: [
        'user-read-private',
        'user-read-email',
        'user-modify-playback-state',
        'user-read-playback-state',
        'user-read-currently-playing',
      ].join(' '),
      redirect_uri: `${window.location.origin}${PATH_HOME}`,
    });
    window.location.href = authUrl.href;
  };

  const getCurrentTrack = async () => {
    const token = spotifyClient.getAccessToken();
    const response = await axios
      .get('https://api.spotify.com/v1/me/player/currently-playing', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(console.error);
    if (response) {
      return response.data;
    }
  };

  const skipToNext = async () => {
    const token = spotifyClient.getAccessToken();
    const response = await axios
      .post(
        'https://api.spotify.com/v1/me/player/next',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch(console.error);
    if (response) {
      return response.data;
    }
  };

  const skipToPrevious = async () => {
    const token = spotifyClient.getAccessToken();
    const response = await axios
      .post(
        'https://api.spotify.com/v1/me/player/previous',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch(console.error);
    if (response) {
      return response.data;
    }
  };

  const play = async () => {
    const token = spotifyClient.getAccessToken();
    const response = await axios
      .put(
        'https://api.spotify.com/v1/me/player/play',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch(console.error);
    if (response) {
      return response.data;
    }
  };

  const pause = async () => {
    const token = spotifyClient.getAccessToken();
    const response = await axios
      .put(
        'https://api.spotify.com/v1/me/player/pause',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch(console.error);
    if (response) {
      return response.data;
    }
  };

  return (
    <SpotifyContext.Provider
      value={{
        user,
        loading,
        isAuthenticated,
        loginWithRedirect,
        getCurrentTrack,
        skipToNext,
        skipToPrevious,
        play,
        pause,
        getToken: () => spotifyClient.getAccessToken(),
        getUser: (id: string) => spotifyClient.getUser(id),
        searchTracks: (term: string) => spotifyClient.search(term, ['track']),
        getTracks: (ids: string[]) => spotifyClient.getTracks(ids),
      }}
    >
      {children}
    </SpotifyContext.Provider>
  );
};
