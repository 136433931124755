import React from 'react';
import {
  Card,
  CardMedia,
  Typography,
  makeStyles,
  IconButton,
  Avatar,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

import { FullRequest } from '../api/types';
import { useCollab } from '../api/CollabProvider';

const useStyles = makeStyles((theme) => ({
  searchBox: {
    padding: 10,
    background: theme.palette.common.black,
  },
  cardStyles: {
    display: 'flex',
    height: 'fit-content',
  },
  cardMedia: {
    width: 54,
    minWidth: 54,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 10px',
    flex: 1,
    minWidth: 0,
  },
  textStyles: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  buttonContainer: {
    padding: 10,
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackRequestContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    gridGap: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export interface TrackRequestProps {
  request: FullRequest;
}

export const TrackRequest: React.FC<TrackRequestProps> = ({ request }) => {
  const { approveRequest, denyRequest, hostView, collaborators } = useCollab();
  const classes = useStyles();
  return (
    <div className={classes.trackRequestContainer}>
      <Avatar
        alt={collaborators[request.user_id]?.display_name}
        src={collaborators[request.user_id]?.images?.[0]?.url}
      />
      <Card className={classes.cardStyles}>
        {request.track_info && (
          <CardMedia
            className={classes.cardMedia}
            image={request.track_info?.album.images[0]?.url}
            title={request.track_info?.album.name}
          />
        )}
        <div className={classes.cardContent}>
          <Typography variant="caption" className={classes.textStyles}>
            {request.track_info?.name}
          </Typography>
          <Typography
            className={classes.textStyles}
            variant="caption"
            color="textSecondary"
          >
            {request.track_info?.artists
              .map((artist) => artist.name)
              .join(', ')}
          </Typography>
        </div>
        {hostView && (
          <div className={classes.buttonContainer}>
            <IconButton size="small" onClick={() => approveRequest(request.id)}>
              <Check color="primary" />
            </IconButton>
            <IconButton size="small" onClick={() => denyRequest(request.id)}>
              <Close color="error" />
            </IconButton>
          </div>
        )}
      </Card>
    </div>
  );
};
