import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { useSpotify } from '../api/SpotifyProvider';
import { Layout } from './Layout';

export interface LayoutRouteProps extends RouteProps {
  requireAuth?: boolean;
}

export const LayoutRoute: React.FC<LayoutRouteProps> = ({
  requireAuth = true,
  component,
  ...routeProps
}) => {
  const Component: any = component;
  const { loading, isAuthenticated } = useSpotify();
  return (
    <Route {...routeProps}>
      {(props) => (
        <Layout>
          {loading ? (
            'Loading...'
          ) : requireAuth && !isAuthenticated ? (
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          )}
        </Layout>
      )}
    </Route>
  );
};
