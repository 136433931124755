import React, { useEffect, useState } from 'react';
import { useCollab } from '../api/CollabProvider';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  Typography,
  TextField,
  makeStyles,
  Card,
  CardMedia,
  CircularProgress,
} from '@material-ui/core';
import { useSpotify } from '../api';
import { PATH_SESSION } from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  trackContainer: {
    display: 'grid',
    gridGap: 5,
    overflow: 'auto',
    padding: 10,
  },
  searchBox: {
    padding: 10,
    background: theme.palette.common.black,
  },
  cardStyles: {
    display: 'flex',
    height: 'fit-content',
  },
  cardMedia: {
    width: 76,
    minWidth: 76,
  },
  cardContent: {
    padding: '12px 14px',
  },
  textStyles: {
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  emptyContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    opacity: 0.5,
    padding: 10,
  },
}));

export const Search: React.FC<RouteComponentProps<{ code: string }>> = ({
  match,
}) => {
  const [searching, setSearching] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedTrack, setSelectedTrack] = useState(false);
  const [tracks, setTracks] = useState<SpotifyApi.TrackObjectFull[]>([]);
  const { searchTracks } = useSpotify();
  const { activeSession, joinSession, sendRequest } = useCollab();
  const classes = useStyles();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearching(true);
      setDebouncedSearch(searchTerm);
    }, 500);
    return () => {
      setSearching(false);
      clearTimeout(timeout);
    };
  }, [searchTerm, setDebouncedSearch]);

  useEffect(() => {
    if (debouncedSearch === '') {
      setSearching(false);
      setTracks([]);
    } else {
      searchTracks(debouncedSearch).then(({ tracks }) => {
        setSearching(false);
        setTracks(tracks?.items || []);
      });
    }
  }, [debouncedSearch, searchTracks, setSearching]);

  useEffect(() => {
    if (activeSession?.code !== match.params.code) {
      joinSession(match.params.code);
    }
  }, [activeSession, match, joinSession]);

  return selectedTrack ? (
    <Redirect to={PATH_SESSION.replace(':code', match.params.code)} />
  ) : (
    <div className={classes.container}>
      <div className={classes.searchBox}>
        <TextField
          style={{ width: '100%' }}
          label="Search for a track"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <div className={classes.trackContainer}>
        {tracks.map((track) => {
          const [albumArt] = track.album.images;
          return (
            <Card
              className={classes.cardStyles}
              onClick={() => {
                sendRequest(track.id);
                setSelectedTrack(true);
              }}
              key={track.id}
            >
              <CardMedia
                className={classes.cardMedia}
                image={albumArt.url}
                title={track.album.name}
              />
              <div className={classes.cardContent}>
                <Typography className={classes.textStyles}>
                  {track.name}
                </Typography>
                <Typography
                  className={classes.textStyles}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {track.artists.map((artist) => artist.name).join(', ')}
                </Typography>
              </div>
            </Card>
          );
        })}
        {tracks.length === 0 && (
          <div className={classes.emptyContainer}>
            {searching ? (
              <CircularProgress />
            ) : (
              <Typography className={classes.emptyText}>
                {debouncedSearch === ''
                  ? `Try searching for a song ☝️`
                  : `We couldn't find anything for ${debouncedSearch} 🤔`}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
