import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Typography,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { useCollab } from '../api/CollabProvider';
import { PATH_SESSION_SEARCH } from '../utils/constants';
import { TrackRequest } from '../components/TrackRequest';
import { Player } from '../components/Player';

const useStyles = makeStyles((theme) => ({
  trackContainer: {
    display: 'grid',
    gridGap: 5,
    overflow: 'auto',
    padding: 10,
  },
  addContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    opacity: 0.5,
    padding: 10,
  },
  header: {
    textAlign: 'center',
    paddingTop: 10,
  },
}));

export const Session: React.FC<RouteComponentProps<{ code: string }>> = ({
  match,
}) => {
  const { activeSession, joinSession, activeRequests, hostView } = useCollab();
  const classes = useStyles();

  useEffect(() => {
    if (activeSession?.code !== match.params.code) {
      joinSession(match.params.code);
    }
  }, [activeSession, match, joinSession]);

  const pendingRequests = useMemo(
    () => activeRequests?.filter(({ status }) => status === 'pending'),
    [activeRequests]
  );

  return activeSession ? (
    <div>
      {hostView ? (
        <Player />
      ) : (
        <Typography
          variant="h6"
          className={classes.header}
        >{`${activeSession?.host_info?.display_name}'s Session • ${activeSession.code}`}</Typography>
      )}
      <div className={classes.trackContainer}>
        {pendingRequests?.map((request) => (
          <TrackRequest key={request.id} request={request} />
        ))}
        {pendingRequests?.length === 0 && (
          <div className={classes.addContainer}>
            <Typography className={classes.emptyText}>
              Pending requests will show up here
            </Typography>
          </div>
        )}
        <div className={classes.addContainer}>
          <Link to={PATH_SESSION_SEARCH.replace(':code', match.params.code)}>
            <IconButton>
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.addContainer}>
      <CircularProgress />
    </div>
  );
};
