import React, { useState, useMemo } from 'react';
import { useSpotify } from '../api/SpotifyProvider';
import { useCollab } from '../api/CollabProvider';
import { Button, Typography, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  codeContainer: {
    display: 'flex',
  },
  textField: {
    borderRadius: '4px 0 0 4px',
  },
  joinButton: {
    borderRadius: '0 4px 4px 0',
  },
}));

export interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const [code, setCode] = useState('');
  const [touched, setTouched] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useSpotify();
  const { joinSession, createSession } = useCollab();
  const classes = useStyles();
  const validCode = useMemo(() => !code.match(/[0-9]/g) && code.length === 4, [
    code,
  ]);
  const error = useMemo(() => code !== '' && touched && !validCode, [
    code,
    touched,
    validCode,
  ]);

  return (
    <div className={classes.container}>
      {isAuthenticated ? (
        <>
          <Typography variant="h4" gutterBottom>
            Welcome to Collablists!
          </Typography>
          <Typography variant="h6" gutterBottom>
            Join an existing session
          </Typography>
          <div className={classes.codeContainer}>
            <TextField
              value={code}
              InputProps={{ className: classes.textField }}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
              onFocus={() => setTouched(false)}
              onBlur={() => setTouched(true)}
              error={error}
              helperText={error ? 'Code must be 4 letters' : undefined}
              variant="outlined"
              label="Session code"
            />
            <Button
              className={classes.joinButton}
              variant="contained"
              color="primary"
              onClick={() => {
                if (validCode) {
                  joinSession(code);
                }
              }}
              disabled={!validCode}
            >
              Join
            </Button>
          </div>
          <Typography variant="h6" gutterBottom>
            or
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => createSession()}
          >
            Create a session
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => loginWithRedirect()}
        >
          Login to Spotify
        </Button>
      )}
    </div>
  );
};
