import { Session, RequestStatus, Request } from './types';

export interface Message<A extends string, P extends Object> {
  action: A;
  payload: P;
}

export const JOINED_SESSION = 'JOINED_SESSION';
export type JOINED_SESSION = typeof JOINED_SESSION;
export interface JoinedSessionPayload {
  session: Session;
  requests: Request[];
}
export type JoinedSession = Message<JOINED_SESSION, JoinedSessionPayload>;

export const UPDATED_REQUESTS = 'UPDATED_REQUESTS';
export type UPDATED_REQUESTS = typeof UPDATED_REQUESTS;
export interface UpdatedRequestsPayload {
  session_id: number;
  requests: Request[];
}
export type UpdatedRequests = Message<UPDATED_REQUESTS, UpdatedRequestsPayload>;

export type MessagesRecieved = JoinedSession | UpdatedRequests;

export const JOIN_SESSION = 'JOIN_SESSION';
export type JOIN_SESSION = typeof JOIN_SESSION;
export interface JoinSessionPayload {
  code: string;
  password?: string;
}
export type JoinSession = Message<JOIN_SESSION, JoinSessionPayload>;

export const CREATE_SESSION = 'CREATE_SESSION';
export type CREATE_SESSION = typeof CREATE_SESSION;
export interface CreateSessionPayload {}
export type CreateSession = Message<CREATE_SESSION, CreateSessionPayload>;

export const SEND_REQUEST = 'SEND_REQUEST';
export type SEND_REQUEST = typeof SEND_REQUEST;
export interface SendRequestPayload {
  session_id: number;
  track_id: string;
}
export type SendRequest = Message<SEND_REQUEST, SendRequestPayload>;

export const RESPOND_TO_REQUEST = 'RESPOND_TO_REQUEST';
export type RESPOND_TO_REQUEST = typeof RESPOND_TO_REQUEST;
export interface RespondToRequestPayload {
  session_id: number;
  request_id: number;
  response: Exclude<RequestStatus, 'pending'>;
}
export type RespondToRequest = Message<
  RESPOND_TO_REQUEST,
  RespondToRequestPayload
>;

export type MessagesSent =
  | JoinSession
  | CreateSession
  | SendRequest
  | RespondToRequest;
