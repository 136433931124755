import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import io from 'socket.io-client';

import { useSpotify } from './SpotifyProvider';
import { FullRequest, FullSession } from './types';
import {
  MessagesRecieved,
  MessagesSent,
  JOINED_SESSION,
  JOIN_SESSION,
  SEND_REQUEST,
  RESPOND_TO_REQUEST,
  UPDATED_REQUESTS,
  CREATE_SESSION,
} from './messages';
import { withRouter, RouteComponentProps } from 'react-router';
import { PATH_SESSION } from '../utils/constants';

export interface CollabState {
  activeSession?: FullSession;
  activeRequests?: FullRequest[];
  collaborators: Record<string, SpotifyApi.UserObjectPublic | undefined>;
}
export interface CollabMessages {
  joinSession: (code: string, password?: string) => void;
  sendRequest: (trackId: string) => void;
  approveRequest: (requestId: number) => void;
  denyRequest: (requestId: number) => void;
  createSession: () => void;
}
export interface CollabContext extends CollabState, CollabMessages {
  connected: boolean;
  hostView: boolean;
}

export const CollabContext = React.createContext<CollabContext>(
  {} as CollabContext
);
export const useCollab = (): CollabContext =>
  useContext<CollabContext>(CollabContext);
export const CollabContextProvider: React.FC<RouteComponentProps> = ({
  children,
  history,
}) => {
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState<SocketIOClient.Socket>();
  const [collabState, setCollabState] = useState<CollabState>({
    collaborators: {},
  });
  const {
    loading,
    isAuthenticated,
    getToken,
    user,
    getUser,
    getTracks,
  } = useSpotify();
  const updateCollabState = useCallback(
    (
      update:
        | Partial<CollabState>
        | ((prev: CollabState) => Partial<CollabState>)
    ) => {
      setCollabState((oldState) => ({
        ...oldState,
        ...(typeof update === 'function' ? update(oldState) : update),
      }));
    },
    [setCollabState]
  );
  const { activeSession, activeRequests, collaborators } = collabState;
  const { host_id, id } = activeSession || {};

  useEffect(() => {
    if (host_id) {
      getUser(host_id).then((host) => {
        updateCollabState((oldState) =>
          oldState.activeSession
            ? {
                activeSession: { ...oldState.activeSession, hostInfo: host },
              }
            : oldState
        );
      });
    }
  }, [host_id, updateCollabState, getUser]);

  useEffect(() => {
    const trackIds = activeRequests
      ?.filter(({ track_info }) => !track_info)
      .map(({ track_id }) => track_id);
    if (trackIds?.length) {
      getTracks(trackIds).then(({ tracks }) => {
        updateCollabState((oldState) =>
          oldState.activeRequests
            ? {
                activeRequests: oldState.activeRequests.map((request) => {
                  const match = tracks.find(
                    (track) => track.id === request.track_id
                  );
                  return match ? { ...request, track_info: match } : request;
                }),
              }
            : oldState
        );
      });
    }
    activeRequests?.forEach(({ user_id }) => {
      if (!collaborators[user_id]) {
        getUser(user_id).then((user) => {
          updateCollabState((oldState) => ({
            collaborators: {
              ...oldState.collaborators,
              [user_id]: user,
            },
          }));
        });
      }
    });
  }, [activeRequests, getTracks, updateCollabState, collaborators, getUser]);

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated && user) {
        setSocket(
          io(process.env['REACT_APP_BACKEND_URL'] || '', {
            query: {
              token: getToken(),
              id: user.id,
            },
          })
        );
        setConnected(true);
      } else {
        setSocket((sock) => sock?.disconnect());
        setConnected(false);
      }
    }
  }, [loading, isAuthenticated, getToken, user]);

  useEffect(() => {
    if (socket) {
      socket.on('message', (message: MessagesRecieved) => {
        switch (message.action) {
          case JOINED_SESSION:
            updateCollabState({
              activeSession: message.payload.session,
              activeRequests: message.payload.requests,
            });
            getUser(message.payload.session.host_id).then((host) => {
              updateCollabState({
                activeSession: { ...message.payload.session, host_info: host },
              });
              history.push(
                PATH_SESSION.replace(':code', message.payload.session.code)
              );
            });
            break;
          case UPDATED_REQUESTS:
            updateCollabState({
              activeRequests: message.payload.requests,
            });
            break;
          default:
            break;
        }
      });
      socket.on('disconnect', () => {
        setConnected(false);
      });
    }
  }, [socket, updateCollabState, getUser, history, collaborators]);

  const sendMessage = useCallback(
    (message: MessagesSent) => {
      if (socket) {
        socket.send(message);
      }
    },
    [socket]
  );

  const joinSession = useCallback(
    (code: string) =>
      sendMessage({
        action: JOIN_SESSION,
        payload: { code },
      }),
    [sendMessage]
  );
  const sendRequest = useCallback(
    (track_id: string) =>
      sendMessage({
        action: SEND_REQUEST,
        payload: { session_id: id || -1, track_id },
      }),
    [sendMessage, id]
  );
  const approveRequest = useCallback(
    (request_id: number) =>
      sendMessage({
        action: RESPOND_TO_REQUEST,
        payload: { session_id: id || -1, request_id, response: 'approved' },
      }),
    [sendMessage, id]
  );
  const denyRequest = useCallback(
    (request_id: number) =>
      sendMessage({
        action: RESPOND_TO_REQUEST,
        payload: { session_id: id || -1, request_id, response: 'denied' },
      }),
    [sendMessage, id]
  );
  const createSession = useCallback(
    () =>
      sendMessage({
        action: CREATE_SESSION,
        payload: {},
      }),
    [sendMessage]
  );

  const hostView = useMemo(() => user?.id === activeSession?.host_id, [
    user,
    activeSession,
  ]);

  return (
    <CollabContext.Provider
      value={{
        connected,
        joinSession,
        sendRequest,
        approveRequest,
        denyRequest,
        createSession,
        hostView,
        ...collabState,
      }}
    >
      {children}
    </CollabContext.Provider>
  );
};

export const CollabProvider = withRouter(CollabContextProvider);
