import React, { useState } from 'react';
import {
  makeStyles,
  Avatar,
  Button,
  IconButton,
  Drawer,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Menu as MenuIcon } from '@material-ui/icons';

import { useSpotify } from '../api/SpotifyProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },
  header: {
    padding: 10,
    minHeight: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.common.black,
    color: 'white',
  },
}));

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const { loading, isAuthenticated, user, loginWithRedirect } = useSpotify();

  const [open, setOpen] = useState(false);
  return (
    <div className={classes.container}>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <Typography>Why hello there</Typography>
      </Drawer>
      <header className={classes.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon color="primary" />
          </IconButton>
          <Typography>Collablists</Typography>
        </div>
        {loading ? (
          <Skeleton variant="circle" width={40} height={40} />
        ) : !isAuthenticated ? (
          <Button
            variant="contained"
            color="primary"
            onClick={loginWithRedirect}
          >
            Sign in to Spotify
          </Button>
        ) : (
          <Avatar alt={user?.display_name} src={user?.images?.[0]?.url} />
        )}
      </header>
      {children}
    </div>
  );
};
