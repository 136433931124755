import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Spotify from 'spotify-web-api-js';
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';

import { LayoutRoute } from './components/LayoutRoute';
import { Home, Session, Search } from './pages';
import { SpotifyProvider } from './api/SpotifyProvider';
import { CollabProvider } from './api/CollabProvider';
import {
  PATH_HOME,
  PATH_SESSION,
  PATH_SESSION_SEARCH,
} from './utils/constants';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#1DB954',
    },
    common: {
      black: '#191414',
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <SpotifyProvider client={new Spotify()}>
        <CollabProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <LayoutRoute
                path={PATH_HOME}
                requireAuth={false}
                component={Home}
                exact
              />
              <LayoutRoute path={PATH_SESSION} component={Session} exact />
              <LayoutRoute
                path={PATH_SESSION_SEARCH}
                component={Search}
                exact
              />
            </Switch>
          </ThemeProvider>
        </CollabProvider>
      </SpotifyProvider>
    </BrowserRouter>
  );
}

export default App;
